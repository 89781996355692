import React from 'react';

import './ContactScreen.css';

export const ContactScreen = () => {

    const cartelXunt = require('../../assets/cartelXunta.png');

    return (

        <div className='contact-container'>
            <div className='contact-list' style={{marginTop: '220px'}}>
                <div className='contact-list-item'>
                    <a href="mailto:peakybranders@peakybranders.es">
                        <i className="fa fa-regular fa-envelope fa-2x talk-item"></i>
                    </a>
                </div>
                <div className='contact-list-item'>
                    <a href="https://www.instagram.com/aspeakybranders/">
                        <i className="fa fa-brands fa-instagram fa-2x talk-item"></i>
                    </a>
                </div>
                <div className='contact-list-item'>
                    <a href="https://twitter.com/aspeakybranders">
                        <i className="fa fa-brands fa-twitter fa-2x talk-item"></i>
                    </a>
                </div>
                <div className='contact-list-item'>
                    <a href="https://linkedin.com/company/as-peaky-branders/">
                        <i className="fa fa-brands fa-linkedin fa-2x talk-item"></i>
                    </a>
                </div>
            </div>
            <div style={{paddingTop: '50px'}}>
                <img src={cartelXunt} width='270px' alt='Cartel Xunta' />
            </div>
        </div>
    )
}
