import React from 'react';

import './HomeScreen.css';

export const HomeScreen = () => {

    const title = require('../../assets/asPeakyBrandersTitle.png');

    return (
        <div className='home-container'>
            <div className='home-logo-container'>
                <img src={title} className='home-logo' alt='As Peaky Breanders title' />
                <p>Creatividade transformadora para marcas conscientes (e valentes).</p>
            </div>
        </div>
    )
}
