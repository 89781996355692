import './App.css';

import { ContactScreen } from './components/contact/ContactScreen';
import { HomeScreen } from './components/home/HomeScreen';

function App() {
  return (
    <div>
      <HomeScreen />
      <ContactScreen />
      
    </div>
  );
}

export default App;
